import React from 'react'
import './style.scss'
import { StaticImage } from 'gatsby-plugin-image'

import Intro from '../../animations/Intro'


import Newsletter from '../Form/CF7/Newsletter'
import SocialLinks from '../SocialLinks'
import { useInView } from 'react-intersection-observer'

function Footer(props) {
  const [io, ioInView] = useInView({ triggerOnce: true })


  const d = new Date()
  const year = d.getFullYear()

  return (
    <footer ref={io} className='master-footer c5'>

      <Intro visible={ioInView} in={{ fadeBottom: 750 }} delayIn={0} mounted={true} stay={true} className="logo">
        <div className="aspect-ratio">
          <StaticImage
            src={'../../../static/images/logo-white.png'}
            layout="fixed"
            //aspectRatio={1.56}
            objectFit="contain"
            objectPosition={'50% 50%'}
            placeholder='NONE'
            alt="Peters Pizza LLC Logo"
          />
        </div>
      </Intro>

      <Intro visible={ioInView} in={{ fadeBottom: 750 }} delayIn={150} mounted={true} stay={true} className="overflow-visible z500">
        <div id="contact" className="contact fs-85">
          <Newsletter heading={"Stay up with our hustle!"}/>
        </div>
      </Intro>

      <Intro visible={ioInView} in={{ fadeBottom: 750 }} delayIn={300} mounted={true} stay={true} className="">
        <SocialLinks classes="fs85" hint='left' />
      </Intro>

      <Intro visible={ioInView} in={{ fadeBottom: 750 }} delayIn={450} mounted={true} stay={true} className="overflow-visible">
        <div className='copy'>
          <p>&copy; {year}. Peters Pizzeria LLC. | <a href="https://www.deepsleepstudio.com/" target="_blank" rel="noreferrer noopener">Built by DS/S</a></p>
        </div>
      </Intro>

    </footer>
  )
}

export default Footer