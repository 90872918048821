import React from 'react'
import Newsletter from '../../Form/CF7/Newsletter'

function SignUpOfferModal() {


  return (
    <div id="sign-up-modal">
      <div className=' padd text-center'>
        <h4>
          Sign Up & Receive <br /> 10% off your first order
        </h4>
        <div className='fs-85 padd-top-half'>
        <Newsletter />
        </div>
      </div>
    </div>
  )
}

export default SignUpOfferModal

